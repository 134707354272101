import { defineStore } from 'pinia'
import { useProjectStore } from '@/store/projectStore'
import translation from '@/shared/utils/translation.js'

export const useLocaleStore = defineStore('localeStore', {
  state: () => ({
    locale: null,
  }),
  actions: {
    async setLocale(code = null) {
      const projectStore = useProjectStore()    
      const { VITE_DEFAULT_LOCALE: defaultLocale } = import.meta.env
      const { language } = projectStore?.project
      if (code) {
        this.locale = code
        return
      }
      if (language) {
        this.locale = translation.isLocaleSupported(language) ? language : defaultLocale
        await translation.switchLanguage(this.locale)
        return
      }
      this.locale = defaultLocale
      return
    },
  },
})
