import { nextTick } from 'vue'
import i18n from '@/plugins/i18n'

const translation = {
  get supportedLocales() {
    return import.meta.env.VITE_SUPPORTED_LOCALES.split(',')
  },

  get currentLocale() {
    return i18n.global.locale.value
  },

  set currentLocale(newLocale) {
    i18n.global.locale.value = newLocale
  },

  async switchLanguage(newLocale) {
    await translation.loadLocaleMessages(newLocale)
    translation.currentLocale = newLocale
  },

  async loadLocaleMessages(locale) {
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await import(`../../i18n/${locale}.json`)
      i18n.global.setLocaleMessage(locale, messages.default)
    }
    return nextTick()
  },

  isLocaleSupported(locale) {
    return translation.supportedLocales.includes(locale)
  },
}

export default translation
